/* You can add global styles to this file, and also import other style files */
$grid-columns: 24 !default;
$grid-gutter-width: 12px;

@import "~bootswatch/dist/superhero/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/superhero/bootswatch";

// TODO switch to npm module for font-awesome once it is at v5
//$fa-font-path: "~font-awesome/fonts";
//@import "~font-awesome/scss/font-awesome.scss";
// OR
//$fa-font-path: "~font-awesome-5-css/webfonts";
//@import "~font-awesome-5-css/scss/fontawesome.scss";

.logo-tmdb {
  width:75px;
}

.borderless td {
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}

.results-container {
  color: black;
}

ul {
  list-style-type: none;
}

.bundle-summary.bundle-summary {
  background-color: black;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}

.bundle-jumbotron.bundle-jumbotron {
  margin-bottom: 0;
}

.provider-list-search .rbt-token {
  color: $white;
  background-color: $primary;
}

.rating-star-full {
  color: $yellow;
}

.rating-star-empty {
  color: $gray-500;
}

.review-count {
  color: $gray-500;
}

.saved-review-list {
  max-height: 340px;
}

.scroll-y {
  overflow-y: auto;
}
